import { isEmpty } from '@/utilities/validation';

export const joinErrorMessage = (errorMessage) => {
  // removing duplicates
  const uniqueSet = new Set(errorMessage);
  const uniqueArray = Array.from(uniqueSet);
  // joining error messages
  return uniqueArray
    .reduce((acc, current) => {
      const currentError = !isEmpty(current) ? current : '';
      const space = !isEmpty(acc) && !isEmpty(current) ? '\n' : '';
      return `${acc}${space}${currentError}`;
    }, '');
};
export default { joinErrorMessage };
