import React, {
  useState,
  useContext,
  useEffect,
  cloneElement,
  useRef,
} from 'react';
import { usePopper } from 'react-popper';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { LocaleContext } from '@/contexts/locale-context';
import TextDiv from '@/components/text-div';
import Portal from '@/components/portal';

/**
 * Tooltip is a wrapper around any DOM element that would pop up
 * a div on hover and display provided text content.
 * @param {object} children - The element, hovering over which would trigger the tooltip.
 * @param {string} content - Text content to appear in tooltip bubble.
 * @param {number} maxWidth - Maximum width of the tooltip element,
 * @param {string} backgroundColor - optional background color of the tooltip
 * @param {string} textColor - optional text color of the tooltip
 * @param {string} position - Vertical postion of the tooltip. Defaults to 'top'
 * @param {boolean} triggerOnClick - Whether or not the tooltip should trigger on click instead of mouseover
 * @param {boolean} fullWidthMobile - Whether or not the tooltip should take full screen width in mobile view
 * @param {boolean} disableMouseOver - Whether or not the tooltip should trigger on mouseover
 */
export default function Tooltip({
  children,
  content,
  maxWidth,
  backgroundColor,
  textColor,
  position,
  triggerOnClick,
  fullWidthMobile,
  disableMouseOver,
}) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { textDirection } = useContext(LocaleContext);

  const [contentVisible, setContentVisible] = useState(false);
  const mouseOverEventRef = useRef(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [anchorNode, setAnchorNode] = useState(null);
  const [contentNode, setContentNode] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const resolvePositionFromLocale = () => {
    if (textDirection === 'rtl') {
      if (position === 'top-start') {
        return 'top-end';
      }
      if (position === 'bottom-start') {
        return 'bottom-end';
      }
    }
    return position;
  };

  const getTooltipOffset = () => {
    if (isMobile) {
      return [0, 15];
    }
    if (textDirection === 'rtl') {
      return [30, 15];
    }
    return [-30, 15];
  };

  // initialise Popper.js wrapper
  const { styles, attributes, state: popperState } = usePopper(anchorNode, contentNode, {
    placement: resolvePositionFromLocale(),
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', textDirection === 'rtl' ? 'top-start' : 'top-end'],
        },
      },
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: getTooltipOffset() } },
      {
        name: 'preventOverflow',
        options: {
          padding: 10,
        },
      },
    ],
  });

  // stores coordinates of the hovered element
  const getAnchorCoords = (e) => {
    setAnchorNode(e?.currentTarget);
  };

  const getArrowPosition = () => {
    if (popperState?.placement?.includes('bottom')) {
      return 'bottom-start';
    }
    return 'top-start';
  };

  // when tooltip is shown - add a listener to hide it on scroll
  const hideTooltip = (e) => {
    if (e?.type === 'custom-hide-tooltip-event') {
      setIsFadingOut(true);
      setContentVisible(false);
      return;
    }
    // check if scroll is not within the tooltip portal element
    if (!document.getElementById('absolute-positioned-content').contains(e?.target)) {
      setIsFadingOut(true);
      setContentVisible(false);
    }
  };

  // makes tooltip trigger on click events instead of mouse over
  const getOnClickProps = () => {
    if (!triggerOnClick) return {};
    return {
      onClick: (e) => {
        if (!contentVisible) {
          getAnchorCoords(e);
          setIsFadingOut(false);
          setContentVisible(true);
        } else {
          hideTooltip();
        }
      },
      onKeyDown: (e) => {
        if (e.key === 'Enter' && !contentVisible) {
          getAnchorCoords(e);
          setIsFadingOut(false);
          setContentVisible(true);
        }
        if ((e.key === 'Enter' || e.key === 'Tab') && contentVisible) {
          hideTooltip();
        }
      },
    };
  };

  useEffect(() => {
    if (!contentVisible) {
      return undefined;
    }

    window.addEventListener('scroll', hideTooltip, true);
    window.addEventListener('custom-hide-tooltip-event', hideTooltip, true);
    return () => {
      window.removeEventListener('scroll', hideTooltip, true);
      window.removeEventListener('custom-hide-tooltip-event', hideTooltip, true);
    };
  }, [contentVisible]);

  return (
    <>
      {contentVisible && (
        <Portal>
          <div
            className={`content${fullWidthMobile ? ' full-width' : ''}${isFadingOut ? ' content-fadeout' : ' content-fadein'}`}
            ref={setContentNode}
            style={styles.popper}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attributes.popper}
          >
            <TextDiv selectorId="tooltip-text-content">
              {content}
            </TextDiv>
            <span
              className={`tooltip-arrow-inner ${getArrowPosition()}`}
              ref={setArrowElement}
              style={styles.arrow}
            />
          </div>
        </Portal>
      )}
      {cloneElement(children, {
        ...children.props,
        onMouseOver: (e) => {
          if (contentVisible || disableMouseOver) return;
          getAnchorCoords(e);
          mouseOverEventRef.current = true;
          setTimeout(() => {
            if (mouseOverEventRef.current) {
              setIsFadingOut(false);
              setContentVisible(true);
            }
          }, 100);
        },
        onMouseLeave: () => {
          if (disableMouseOver) return;
          hideTooltip();
        },
        ...(triggerOnClick && getOnClickProps()),
      })}
      <style jsx>
        {`
          .content {
            align-items: center;
            background-color: var(${backgroundColor});
            border-radius: var(--border-radius-small);
            box-shadow: 0 2px 4px 0 var(--color-card-shadow);
            color: var(${textColor});
            display: flex;
            font-size: var( --font-size-tooltip);
            justify-content: flex-start;
            line-height: 20px;
            max-width: ${maxWidth}px;
            padding: 10px 10px;
            text-decoration: none;
            z-index: var(--zindex-tooltip);
            filter: drop-shadow(0 2px 4px var(--color-card-shadow));
          }

          .content.full-width {
            width: calc(100% - 20px);
          }

          .tooltip-arrow-inner {
            border-color: transparent transparent var(${backgroundColor}) var(${backgroundColor});
            border: 10px solid;
            box-sizing: border-box;
            color: var(${backgroundColor});
            height: 0;
          }

          .tooltip-arrow-inner.top-start {
            bottom: -18px;
            clip-path: polygon(0 0, 100% 0, 50% 50%);
          }

          .tooltip-arrow-inner.bottom-start {
            clip-path: polygon(50% 0, 100% 100%, 0 100%);
            top: -13px;
          }

          .content-fadein {
            animation: fadein .3s ease-in-out  forwards;
          }

          .content-fadeout {
            animation: fadeout .3s linear forwards;
          }

          @-webkit-keyframes fadein {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }

          @keyframes fadein {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }

          @-webkit-keyframes fadeout {
            0% { opacity: 1; }
            100% { opacity: 0; }
          }

          @keyframes fadeout {
            0% { opacity: 1; }
            100% { opacity: 0; }
          }

          @media only screen and (max-width: 768px) {
            .content {
              max-width: 300px;
            }

            .content.full-width {
              max-width: unset;
            }
          }
        `}
      </style>
    </>
  );
}

Tooltip.defaultProps = {
  maxWidth: 280,
  backgroundColor: '--color-white',
  textColor: '--color-primary-dark',
  position: 'top-start',
  triggerOnClick: false,
  fullWidthMobile: false,
  disableMouseOver: false,
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  maxWidth: PropTypes.number,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  position: PropTypes.oneOf(['top-start', 'bottom-start']),
  triggerOnClick: PropTypes.bool,
  fullWidthMobile: PropTypes.bool,
  disableMouseOver: PropTypes.bool,
};
